<template>
    <ValidationObserver ref="observer" v-slot="{ invalid }" >
        <b-alert variant="success" show >
            <div class="alert-body">
                <h5 class="mt-1 text-success font-weight-bolder text-center">
                "{{myQuote.phrase || ''}}"
                </h5>
                <p class="font-weight-bold text-success text-justify"><small>{{ quote.author || '' }}</small></p>
            </div>
        </b-alert>
        <b-form @submit.prevent="setSelectedPOS">
            <ValidationProvider name="Punto de venta" rules="required">
                <b-form-group slot-scope="{ valid, errors }" label="Seleccione punto de venta:">
                    <b-form-select :state="errors[0] ? false : (valid ? true : null)" v-model="selected" >
                        <option value="">Seleccione una opción</option>
                        <option v-for="(pv, index) in user.puntosventa" :key="index" :value="pv.id">{{pv.name}}</option>
                    </b-form-select>            
                    <b-form-invalid-feedback>
                    {{ errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </ValidationProvider>
            <b-row>
                <b-col>
                    <b-button block variant="warning" @click="salir">
                        <b-icon icon="power" aria-hidden="true"></b-icon> Salir
                    </b-button>                    
                </b-col>
                <b-col>
                    <b-button block type="submit" :variant="invalid ? 'danger':'primary' " :disabled="invalid">                        
                        Ingresar <b-icon-arrow-right-circle-fill></b-icon-arrow-right-circle-fill>
                    </b-button>
                </b-col>
            </b-row>                                                   
        </b-form>
    </ValidationObserver>                    
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    data() {
        return {
            selected: '',
        }
    },        
    computed:{
        ...mapState('auth',['user','isLogged','quote']),
        myQuote(){
            return this.quote                     
        }       
    },
    methods:{
        ...mapMutations('shop',['setPuntoDeVenta','unsetCanalVenta','unsetPuntoDeVenta']),
        ...mapMutations('auth',['logout']),        
        setSelectedPOS(){
            this.setPuntoDeVenta( this.selected )
            if(this.isLogged){
                this.$router.push({name: 'sales'})
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: `${this.quote.author}`,
                        icon: 'BellIcon',
                        text: `🎉🎉 ${this.quote.phrase}`,
                        variant:'success'              
                    },
                },{
                    position:'bottom-right',
                    timeout:7000,
                })
            }        
        },
        salir(){
            this.logout()
            this.unsetCanalVenta()
            this.unsetPuntoDeVenta()            
        }
    }
}
</script>


