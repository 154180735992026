<template>    
  <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Inicio de sesión" />
        </div>
      </b-col>     
</template>

<script>
export default {
  props:{
    imgUrl: {
      type: String,
      required: true,
    }
  }
}
</script>



