<template>
    <div>
        <div :class="windowWidth > 991 ? 'd-none' : '' ">
            <center>
                <b-link>
                    <img :src="require('@/assets/images/logo/logo-the-fives.svg')" alt="The Fives" height="auto" width="250">
                    <hr/>
            </b-link>
            </center>
        </div>
        <b-alert variant="success" show v-if="quote">
            <div class="alert-body text-justify">
                <h5 class="mt-1 text-success font-weight-bolder text-center">
                    "{{myQuote.phrase || ''}}"
                </h5>
                <p class="font-weight-bold text-success text-center"><small>{{myQuote.author || ''}}</small></p>
            </div>
        </b-alert>    
        <validation-observer ref="loginForm" #default="{invalid}" >
            <b-form class="auth-login-form mt-2" @submit.prevent="login" style="padding-bottom:3rem;">
                <!-- usuario -->
                <b-form-group label="Nombre de usuario" label-for="login-email"
                >
                <validation-provider
                    #default="{ errors }"
                    name="username"
                    vid="email"
                    rules="required"
                >
                    <b-form-input
                    id="login-email"
                    v-model="user.username"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="usuario@thefives.com.mx"
                    />
                    <small class="text-danger">{{ errors[0] ? 'El ID de usuario es obligatorio' : '' }}</small>
                </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                <div class="d-flex justify-content-between">
                    <label for="login-password">Contraseña</label>
                </div>
                <validation-provider
                    #default="{ errors }"
                    name="contraseña"
                    vid="password"
                    rules="required"
                >
                    <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                    >
                    <b-form-input
                        id="login-password"
                        v-model="user.password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="Contraseña"
                    />
                    <b-input-group-append is-text>
                        <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                        />
                    </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] ? 'La contraseña es obligatoria' : '' }}</small>
                </validation-provider>
                </b-form-group>

                <!-- submit button -->
                <b-button type="submit" variant="primary" block :disabled="invalid">
                    Continuar <b-icon icon="arrow-right-circle-fill" style="color: #ffffff;"></b-icon>
                </b-button>
            </b-form>
        </validation-observer>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { phrases } from '@/data/phrases'  
 

export default {
    mounted(){
        this._setPhrase()
    },
    props: {
        user: {
            type: Object,
            required: true,
        }
    },
    mixins: [togglePasswordVisibility],    
    computed:{
        ...mapState('auth',['quote']),
        ...mapState('appConfig',['windowWidth']),
        myQuote(){
            return this.quote                 
        }
    },
    methods:{
        ...mapMutations('auth',['setPhrase']),
        login(){
            this.$emit('login-user', this.user)                                                            
        },
        _setPhrase(){
            this.setPhrase( phrases[ Math.floor(Math.random() * (20 - 1)) + 1 ] )
        }
    }
}
</script>


